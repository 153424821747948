<template>
  <main>
    <trac-loading v-if="isLoading" />
    <div class="flex w-full justify-center mt-16 items-center">
      <div
        ref="plac"
        class="max-w-4xl auth-shadow p-5 flex-col pb-16 rounded-md w-full flex items-center justify-center"
      >
        <div class="flex items-center justify-center flex-col mt-5">
          <img src="@/assets/svg/otherLogo.svg" class="w-12 h-12" alt="" />
          <h2 class="text-sm pt-2 font-medium text-primaryBlue tracking-wide">
            traction apps
          </h2>
        </div>
        <trac-validation-observer slim v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(sendEmail)"
            class="max-w-xl ma w-full mt-12"
          >
            <h1 class="text-lg font-medium text-accentDark">Forgot Password</h1>
            <trac-validation-provider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <trac-input
                v-model.trim="email"
                placeholder="Email Address"
                class="mt-5"
                ref="emails"
                type="email"
                tag="div"
              />
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <div class="mt-8 flex items-center justify-center flex-col">
              <trac-button buttonType="submit" class="w-full max-w-xs"
                ><div class="py-1">Send Email</div></trac-button
              >
            </div>
          </form>
        </trac-validation-observer>
      </div>
    </div>
  </main>
</template>

<script>
import { eventBus } from "./../main";

export default {
  data() {
    return {
      isLoading: false,
      email: "",
    };
  },
  mounted() {},
  methods: {
    async sendEmail() {
      this.isLoading = true;

      const payload = {
        email: this.email,
      };

      await this.$store.dispatch("RESET_PASSWORD", payload);
      const res = this.$store.getters["FORGOT_PASSWORD_RES"];

      if (res.status) {
        this.email = "";
        this.$router.replace({ name: 'Login' });
      }
      eventBus.$emit("trac-alert", {
        message: res.message || "Error: Netowrk error occured.",
      });

      this.isLoading = false;
    },
  },
};
</script>

<style></style>
